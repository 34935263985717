import React from "react"
import { graphql } from "gatsby"
import Image from "gatsby-image"
import Anime from "react-anime"
import Layout from "../components/layout"
import SEO from "../components/seo"
import GuidesAccordion from "../components/guides-accordion"
import CTA from "../components/cta"
import styled from "styled-components"
import AspectRatio from "react-aspect-ratio"
import get from 'lodash.get';
// import { AnchorLink } from "gatsby-plugin-anchor-links"

export default class GuidesPage extends React.Component {
  constructor(props) {
    super(props)
    const {
      data: {
        allWordpressWpGuides,
        wordpressPage: { acf, yoast_meta },
      },
      pageContext: { slug },
    } = props

    this.state = {
      loaded: false,
      acf,
      slug,
      allWordpressWpGuides,
      yoast_meta,
      active:
        typeof window !== "undefined" ? window.location.hash.slice(1) : "",
    }

    this.toggleActive = this.toggleActive.bind(this)
  }

  async toggleActive({ slug }, alwaysOpen = false) {
    await this.setState({
      ...this.state,
      active: alwaysOpen || slug !== this.state.active ? slug : null,
    })
  }

  componentDidMount() {
    setTimeout(()=>{
      this.setState(({
        ...this.state,
        loaded:true
      }))
    },800);
  }

  render() {
    return (
      <Layout>
        <SEO yoast={this.state.yoast_meta} />

        {/* Hero */}
        <section className="guides-hero lg:my-24 md:my-20 sm:my-16 mb-16 mt-12">
          <div className="o-wrapper text-center">
            <Anime
              delay={100}
              autoplay={!this.state.loaded}
              translateY={[!this.state.loaded ? 26 : 0, 0]}
              opacity={[!this.state.loaded ? 0 : 1, 1]}
            >
              <h1 className="u-h1 mb-6">{get(this, 'state.acf.hero.heading', '')}</h1>
              <p className="text-20px">{get(this, 'state.acf.hero.content', '')}</p>
            </Anime>
          </div>
        </section>

        {/* Grid */}
        <section className="guides-grid sm:my-24 my-16">
          <div className="o-wrapper">
            <div className="flex flex-wrap -ml-2 -my-1">
              {get(this, 'state.acf.guide_grid') && this.state.acf.guide_grid.map((gridItem, i) => (
                <GridItem
                  key={`gridItem${gridItem.link.post_name}`}
                  gridItem={gridItem}
                  loaded={this.state.loaded}
                  toggleActive={this.toggleActive}
                  i={i}
                />
              ))}
            </div>
          </div>
        </section>

        {/* Accordion */}
        <GuidesAccordion
          guides={this.state.allWordpressWpGuides.edges}
          toggleActive={this.toggleActive}
          active={this.state.active}
        />

        <CTA
          heading={get(this, 'state.acf.cta.heading', '')}
          content={get(this, 'state.acf.cta.content', '')}
          image={get(this, 'state.acf.cta.image', {})}
          button={get(this, 'state.acf.cta.button', '')}
        />
      </Layout>
    )
  }
}

const GridItemWrapper = styled.div`
  .imp-h-full {
    height: 100% !important;
  }

  .gatsby-image-wrapper {
    min-height: 69%;
    max-width: 100%;
    width: 100% !important;
  }

  .grid-item__bg {
    opacity: 0;
    transform: scaleX(1.1);
    transform-origin: left center;
    transition-duration: 0.4s;
  }

  .grid-item__image {
    height:100%!important;
    img {
      object-fit: contain !important;
    }
  }

  > div:hover {
    .grid-item__bg {
      opacity: 1;
      transform: none;
    }
  }
`

function GridItem({ loaded, gridItem, i, toggleActive }) {
  let classes = `guide-grid-item pl-2 py-1`
  if (i === 0) {
    classes += " md:w-1/3 sm:w-1/2 w-full"
  } else if (i < 3) {
    classes += " md:w-1/3 w-1/2"
  } else {
    classes += " md:w-1/4 w-1/2"
  }


  const [hovered, setHovered] = React.useState(false);

  return (
    <GridItemWrapper className={classes}>
      <AspectRatio ratio={"314/340"}>
        {/* eslint-disable-next-line */}
        <div
          className="overflow-hidden"
          style={{ backgroundColor: "#fafafa" }}
          onMouseEnter={e=>setHovered(true)}
          onMouseLeave={e=>setHovered(false)}
          onClick={() => {
            toggleActive({slug:gridItem.link.post_name}, true)
          }}
        >
          <Anime
            delay={200 + i * 100}
            translateY={[!loaded ? 26 : 0, 0]}
            opacity={[!loaded ? 0 : 1, 1]}
            className="h-full "
          >
            <a
              href={`/guides#${gridItem.link.post_name}`}
              onClick={e => {
                e.preventDefault();
                setTimeout(()=>{
                  window.scrollTo({
                    top : document.querySelector('#'+gridItem.link.post_name).getBoundingClientRect().top - 81,
                    behavior: 'smooth'
                  });
                }, 100);
              }}
              className="w-full h-full flex flex-col justify-center relative"
            >

              <img
                className="absolute inset-0 h-full w-full grid-item__bg transition duration-500 ease-in-out"
                src={require(`../images/bgs/${gridItem.hover_background_colour}.jpg`)}
                alt=""
              />
              <Anime
                scale={!hovered ? 1 : 1.2}
                easing="easeOutElastic"
                className="h-full flex "
              >
                <Image
                  fixed={gridItem.image.localFile.childImageSharp.fixed}
                  className="grid-item__image"
                  draggable={false}
                />
              </Anime>
            </a>
          </Anime>
        </div>
      </AspectRatio>
    </GridItemWrapper>
  )
}

export const query = graphql`
  query Guides {
    wordpressPage(slug: { eq: "guides" }) {
      title
      ...Yoast
      acf {
        hero {
          heading
          content
        }
        guide_grid {
          link {
            post_name
          }
          hover_background_colour
          image {
            localFile {
              childImageSharp {
                fixed(
                  width: 504
                  fit: COVER
                  cropFocus: CENTER
                  background: "transparent"
                ) {
                  ...GatsbyImageSharpFixed_withWebp_noBase64
                }
              }
            }
          }
        }
        cta {
          heading
          content
          button {
            title
            url
            target
          }
          image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }

    allWordpressWpGuides(filter: {slug: {ne: "guide-template"}}) {
      edges {
        node {
          id
          title
          slug
          acf {
            guide {
              operating_systems
              teaser
              featured
            }
          }
          parent_element {
            id
            slug
          }
        }
      }
    }
  }
`
