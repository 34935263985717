import React from 'react'
import Anime from 'react-anime';
import {Link} from 'gatsby';
import styled from 'styled-components';
import get from 'lodash.get';

const Heading = styled.h3`
  @media screen and (max-width:640px) {
    font-size:1.75rem;
  }
`;

export default class GuidesAccordion extends React.PureComponent {

  constructor(props) {
    super(props);
    const { guides, toggleActive } = this.props;

    this.state = {
      guides,
      parents: [],
    };

    this.toggleActive = toggleActive;
  }

  componentDidMount() {
    this.filter();
    if (typeof window !== 'undefined') {
      setTimeout(()=>{
        if (this.props.active.length) {
          const active = document.querySelector(`#${this.props.active}`);
          if (active) {
            const elTop = active.getBoundingClientRect().top;
            if (elTop > 10) { // bad test to make sure element has rerendered and messed number up
              window.scrollTo({
                top : active.getBoundingClientRect().top - 81,
                behavior: 'smooth'
              });
            }
          }
        }
      }, 100);
    }
  }

  filter() {
    let parents = [];

    this.props.guides
    .filter(({ node: { parent_element } }) => parent_element === null)
    .forEach(item => {
      // add id as key for easier search later
      parents[item.node.id] = {
        ...item.node,
        children: []
      }
    });

    const children = this.props.guides.filter(({ node: { parent_element } }) => parent_element !== null && parent_element.id)
    children.forEach(child => {
      // Find the parent by its array key
      const id = child.node.parent_element.id;
      if (parents[id] && parents[id].children) {
        // Current child adds itself to the parent
        parents[id].children.push(child);
      }
    });

    // Sort by large number of children first
    // console.log(parents);
    parents = Object.values(parents).sort((a, b) => a.children.length < b.children.length ? 1 : -1);
    // console.log(children);

    this.setState({...this.state, parents })
  }

  render() {
    return (
      <section className="md:my-32 my-20 guides-accordion">
        <div className="o-wrapper">
          {this.state.parents.map((node) =>
            <div key={node.id}>
              <GuideCategory
                node={node}
                active={this.props.active}
                clickEvent={this.toggleActive}
              />
            </div>
          )}
        </div>
      </section>
    )
  }
}


const GuideCategory = ({ node, clickEvent, active }) => {
  const children = node.children;
  const childCount = children ? children.length : 0;
  const isActive = active === node.slug;

  return (
    <div
      id={node.slug}
      className={`mx-auto cursor-pointer select-none guide-category ${isActive ? ' guide-category--active ': ''}`}
      style={{ maxWidth: '1052px', opacity: (isActive ? '1' : '1') }}
    >
      {/* eslint-disable-next-line */}
      <header
        className="sm:py-8 py-6 border-b border-grey-light flex justify-between items-center"
        onClick={() => clickEvent(node)}
      >
        <Heading className="u-h3">{node.title}</Heading>
        <div className="flex items-center">
          <div className="hidden sm:block">{childCount} Guide{childCount === 1 ? '': 's'}</div>
          <img src={require('../images/circle-arrow.svg')} className={`ml-3 transform ${isActive ? '' : 'rotate-180'}`} alt="" />
        </div>
      </header>

      {childCount > 0 && children &&
        <GuideCategoryGuide
          edges={children}
          isActive={isActive}
        />
      }
    </div>
  )
}


const GuideCategoryGuide = ({ edges, isActive }) => {
  const featured = edges.filter(({node}) => node.acf.guide && node.acf.guide.featured === true);
  const normal = edges.filter(({node}) => node.acf.guide && node.acf.guide.featured !== true);

  return (
    <main className={`${!isActive ? 'hidden' : ''} pt-8 sm:py-10 overflow-hidden ${normal.length > 0 ? '' : 'pb-4'}`}>

      {/* Featured */}
      {featured.length > 0 &&
        <div className={`grid md:grid-cols-${featured.length === 1 ? '1' : '2'} gap-3 mb-6`}>
          {featured.map(({node}, i) =>
            <Anime delay={i*50} opacity={[0, 1]} key={`guideitem-featured-${node.id+i}`}>
              <Link to={`/guides/${node.parent_element.slug}/${node.slug}`} className="block cursor-pointer p-8 py-10 bg-blue hover:bg-blue-light transition duration-100 hover-underline">
                {/* Icons */}
                {node.acf.guide && node.acf.guide.operating_systems && node.acf.guide.operating_systems.length &&
                <div className="flex -ml-2">
                  {node.acf.guide.operating_systems.map(icon =>
                    <img key={`guideitem-featured-icon-${icon+node.id+i}`} className="pl-2" src={require(`../images/icons/${icon}.svg`)} alt={icon} />
                  )}
                </div>
                }
                <h3 className="u-h5 mb-4 mt-6 hover-underline__target" dangerouslySetInnerHTML={{ __html: node.title }} />
                <p className="mb-1">{node.acf.guide.teaser}</p>
              </Link>
            </Anime>
          )}
        </div>
      }

      {/* Normal */}
      {normal.length > 0 &&
        <div>
          {normal.map(({node}, i) =>
            <Link to={`/guides/${node.parent_element.slug}/${node.slug}`} className="flex flex-col md:flex-row items-start cursor-pointer md:items-center py-8 md:py-8 pr-4 border-b border-grey-light hover-underline" key={`guideitem-normal-${node.id+i}`}>

              {/* Icons */}
              <div className="flex flex-col items-center justify-center mr-6" style={{ flex: '0 0 49px' }}>
                {node.acf.guide && node.acf.guide.operating_systems && node.acf.guide.operating_systems.length &&
                  <img src={require(`../images/icons/${node.acf.guide.operating_systems[0]}.svg`)} alt={node.acf.guide.operating_systems[0]} />
                }
              </div>

              <div className="">
                <h3 className="u-h5 mb-2 mt-4 md:mb-1 md:mt-0 hover-underline__target" dangerouslySetInnerHTML={{ __html: node.title }}/>
                <p>{node.acf.guide.teaser}</p>
              </div>

            </Link>
          )}
        </div>
      }


    </main>
  )
}